import React, { useState } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { FormattedMessage } from "react-intl";

// import '../assets/css/webflow.css';
// import '../assets/css/normalize.css';
// import '../assets/css/solo-arch.webflow.css';

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63667a86befaedf883e34757"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

export function NavLinks({ useFormatMessage, locale, changeLocaleHandler }) {
  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2ef623b44a7c1";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  };
  componentDidMount();
  console.log('NavLinks locale:', locale); // Add this line to log the current locale array
  return (
    <div
      data-animation="default"
      className="af-class-navbar w-nav"
      data-easing2="ease-out-back"
      data-easing="ease-in"
      data-collapse="medium"
      data-w-id="a1e4a4fe-8958-4d97-d653-0e5ab1a1f9e9"
      role="banner"
      data-duration={160}
      data-doc-height={1}
    >

      <nav role="navigation" className="af-class-nav-menu w-nav-menu">
        <ScrollToTop />
        <Link className="af-class-nav-link w-nav-link w--current" to={"/"}>
          <FormattedMessage id="nav.home" defaultMessage="Home" />
        </Link>
        <Link className="af-class-nav-link w-nav-link w--current" to={"/about"}>
          <FormattedMessage id="nav.about" defaultMessage="About" />
        </Link>
        <Link
          className="af-class-nav-link w-nav-link w--current"
          to={"/projects"}
        >
          <FormattedMessage id="nav.work" defaultMessage="Work" />
        </Link>
        <Link
          className="af-class-nav-link w-nav-link w--current"
          to={"/service-detail"}
        >
          <FormattedMessage id="nav.services" defaultMessage="Services" />
        </Link>
        <Link className="af-class-nav-link w-nav-link w--current" to={"/contact"}>
          <FormattedMessage id="nav.contact" defaultMessage="Contact" />
        </Link>
        <a
          onClick={() => changeLocaleHandler(locale === "tr" ? "en" : "tr")}
          className="af-class-nav-link w-nav-link"
        >
          {locale === "tr" ? "EN" : "TR"}
        </a>
        {/* <div
        data-hover="false"
        data-delay={0}
        className="af-class-dropdown w-dropdown"
      >
        <div className="af-class-dropdown-toggle w-dropdown-toggle">
          <div className="af-class-icon-3 w-icon-dropdown-toggle" />
          <img
            src="../images/globe-language-svgrepo-com.svg"
            loading="lazy"
            width={25}
            alt="image"
            className="af-class-image-12-copy"
          />
        </div>
        <nav className="af-class-dropdown-list w-dropdown-list">
          <a
            onClick={() => changeLocaleHandler("en")}
            className="w-dropdown-link"
          >
            <FormattedMessage id="nav.english" defaultMessage="EN" />
          </a>
          <a
            onClick={() => changeLocaleHandler("tr")}
            className="w-dropdown-link"
          >
            <FormattedMessage id="nav.turkish" defaultMessage="TR" />
          </a>
        </nav>
      </div> */}
      </nav>
      <div
        data-w-id="a1e4a4fe-8958-4d97-d653-0e5ab1a1f9f5"
        className="af-class-nav-button w-nav-button"
      >
        <div className="af-class-icon w-icon-nav-menu" />
      </div>
      <div className="af-class-div-block">
        <a href="/" className="af-class-brand w-nav-brand">
          <img
            src="/images/logo_1.svg"
            loading="lazy"
            data-w-id="a1e4a4fe-8958-4d97-d653-0e5ab1a1f9eb"
            alt=""
            className="af-class-image"
          />
          <div className="af-class-text-block">Solo</div>
          <div className="af-class-text-block-2">Architects</div>
        </a>
      </div>
    </div>
  );
}
