import React from 'react';
import GoogleFontsLoader from '../utils/GoogleFontsLoader';

const Loader = () => {
  return (
    <span>
      <GoogleFontsLoader />
      <span className="af-view">
        <div>
          <div className="af-class-utility-page-wrap">
            <div className="af-class-utility-page-content">
              {/* <h1 className="af-class-not-found-404">Loading...</h1> */}
              <h3>Loading...</h3>
              <div>Please wait</div>
            </div>
          </div>
        </div>
      </span>
    </span>
  );
};

export default Loader;
