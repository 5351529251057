/* eslint-disable */

import { NavLinks } from "../components/NavLinks";
import { useFormatMessage } from "../hooks";
import { useLanguageUtils } from "../utils/index";
import React, { useEffect, useState } from "react";
// import { fetchTeamsAndProjects } from '../firebase/fetch';

import GoogleFontsLoader from "../utils/GoogleFontsLoader";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63667a86befaedf883e34757"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

const ServiceDetailView = () => {
  const Projects = useSelector((state) => state.dynamicData.data?.Projects || []);
    const { changeLocaleHandler, locale } = useLanguageUtils();
  //Language Part
  // const dispatch = useDispatch();
  // const changeLocaleHandler = (local) => {
  //     dispatch(setUserLocale(local));
  // };
  // const { locale } = useSelector(
  // (state) => ({
  //     locale: state.preferences.locale,
  // }),
  // shallowEqual
  // );
  // const locales = availableLocales.filter((local) => local !== locale);
  //---------------------

  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2ef58cb44a81d";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
            with (this) {
              eval(arguments[0])
            }
          `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  };
  componentDidMount();

  return (
    <span>
      <GoogleFontsLoader />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/solo-arch.webflow.css);
        `,
        }}
      />
      <span className="af-view">
        <div className="af-class-body-5">
                      <NavLinks
              useFormatMessage={useFormatMessage}
              locale={locale}
              changeLocaleHandler={changeLocaleHandler}
            />
          <div className="af-class-service-detail-section af-class-hero-inner-spacing af-class-wf-section">
            <div className="af-class-container w-container">
              <h1 className="af-class-heading-title">
                {useFormatMessage("services.our")}
              </h1>
              <p className="af-class-paragraph-3">
                {useFormatMessage("services.body")}
              </p>
              <div className="w-layout-grid af-class-grid-service-detail">
                <div id="w-node-c6f76b6d-ab27-257e-4930-d227a5410f9f-cb44a81d">
                  <img
                    src="/images/IMG_2292-2.JPG"
                    loading="lazy"
                    data-w-id="f27038a2-de74-49d9-2fc6-fb3da9f7367b"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 87vw, (max-width: 991px) 90vw, (max-width: 1279px) 92vw, (max-width: 3123px) 97vw, 3030px"
                    srcSet="/images/IMG_2292-2-p-500.jpg 500w, images/IMG_2292-2-p-800.jpg 800w, images/IMG_2292-2-p-1080.jpg 1080w, images/IMG_2292-2-p-1600.jpg 1600w, images/IMG_2292-2-p-2000.jpg 2000w, images/IMG_2292-2-p-2600.jpg 2600w, images/IMG_2292-2.JPG 3030w"
                    alt=""
                    className="af-class-service-detail-image"
                  />
                </div>
                <div id="w-node-_5b0b4144-6996-8c0c-05fe-36654cbe7825-cb44a81d">
                  <div className="w-layout-grid af-class-grid-service-list">
                    <ul
                      role="list"
                      className="af-class-service-list-wrap w-list-unstyled"
                    >
                      <li className="af-class-service-list-item">
                        <span className="af-class-service-icon"> </span>
                        {useFormatMessage("services.projecting")}
                      </li>
                      <li className="af-class-service-list-item">
                        <span className="af-class-service-icon"> </span>
                        {useFormatMessage("services.render")}
                      </li>
                      <li className="af-class-service-list-item">
                        <span className="af-class-service-icon"> </span>
                        {useFormatMessage("services.feasibility")}
                      </li>
                      <li className="af-class-service-list-item">
                        <span className="af-class-service-icon"> </span>
                        {useFormatMessage("services.qc")}
                      </li>
                    </ul>
                    <ul
                      role="list"
                      className="af-class-service-list-wrap w-list-unstyled"
                    >
                      <li className="af-class-service-list-item">
                        <span className="af-class-service-icon"> </span>
                        {useFormatMessage("services.construction")}
                      </li>
                      <li className="af-class-service-list-item">
                        <span className="af-class-service-icon"> </span>
                        {useFormatMessage("services.pm")}
                      </li>
                      <li className="af-class-service-list-item">
                        <span className="af-class-service-icon"> </span>
                        {useFormatMessage("services.MPS")}
                      </li>
                    </ul>
                  </div>
                  <div
                    data-current="Tab 1"
                    data-easing="ease"
                    data-duration-in={300}
                    data-duration-out={100}
                    className="af-class-tabs w-tabs"
                  >
                    <div className="af-class-tab-menu w-tab-menu">
                      <a
                        data-w-tab="Tab 1"
                        className="af-class-tab-link w-inline-block w-tab-link w--current"
                      >
                        <div>{useFormatMessage("services.architecture")}</div>
                      </a>
                      <a
                        data-w-tab="Tab 2"
                        className="af-class-tab-link w-inline-block w-tab-link"
                      >
                        <div>{useFormatMessage("services.interior")}</div>
                      </a>
                      <a
                        data-w-tab="Tab 3"
                        className="af-class-tab-link w-inline-block w-tab-link"
                      >
                        <div>{useFormatMessage("services.landscaping")}</div>
                      </a>
                    </div>
                    <div className="af-class-tab-content w-tab-content">
                      <div
                        data-w-tab="Tab 1"
                        className="af-class-tab-pane w-tab-pane w--tab-active"
                      >
                        <p>{useFormatMessage("services.architecture-body")}</p>{" "}
                        <div className="w-layout-grid af-class-grid-service-list">
                          <ul
                            role="list"
                            className="af-class-service-list-wrap w-list-unstyled"
                          >
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.architecture-list-item-1"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.architecture-list-item-2"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.architecture-list-item-3"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.architecture-list-item-4"
                              )}
                            </li>
                          </ul>
                          <ul
                            role="list"
                            className="af-class-service-list-wrap w-list-unstyled"
                          >
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.architecture-list-item-5"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.architecture-list-item-6"
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        data-w-tab="Tab 2"
                        className="af-class-tab-pane w-tab-pane"
                      >
                        <p>{useFormatMessage("services.interior-body")}</p>{" "}
                        <div className="w-layout-grid af-class-grid-service-list">
                          <ul
                            role="list"
                            className="af-class-service-list-wrap w-list-unstyled"
                          >
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.interior-list-item-1"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.interior-list-item-2"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.interior-list-item-3"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.interior-list-item-4"
                              )}
                            </li>
                          </ul>
                          <ul
                            role="list"
                            className="af-class-service-list-wrap w-list-unstyled"
                          >
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.interior-list-item-5"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.interior-list-item-6"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.interior-list-item-7"
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        data-w-tab="Tab 3"
                        className="af-class-tab-pane w-tab-pane"
                      >
                        <p>{useFormatMessage("services.landscaping-body")}</p>{" "}
                        <p>{useFormatMessage("services.landscaping-body")}</p>{" "}
                        <div className="w-layout-grid af-class-grid-service-list">
                          <ul
                            role="list"
                            className="af-class-service-list-wrap w-list-unstyled"
                          >
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-1"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-2"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-3"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-4"
                              )}
                            </li>
                          </ul>
                          <ul
                            role="list"
                            className="af-class-service-list-wrap w-list-unstyled"
                          >
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-5"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-6"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-7"
                              )}
                            </li>
                            <li className="af-class-service-list-item">
                              <span className="af-class-service-icon"> </span>
                              {useFormatMessage(
                                "services.landscaping-list-item-8"
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="af-class-service-work">
                <h4 className="af-class-heading-14">The way we work</h4>
              </div>
              <img
                alt="image"
                src={
                  locale == "tr"
                    ? "/images/services_tr.png"
                    : "/images/IMG_1042.PNG"
                }
                loading="lazy"
                width={1376}
                className="af-class-image-11-copy"
              />
              <img
                alt="image"
                src={
                  locale == "tr"
                    ? "/images/services_tr_mobile.png"
                    : "/images/Untitled_Artwork.png"
                }
                loading="lazy"
                className="af-class-image-19"
              />{" "}
            </div>
            <div className="w-layout-grid">
              <img
                src="/images/IMG_8241.JPG"
                loading="lazy"
                id="w-node-_7872b4be-4112-e0de-e954-837c26028bbd-cb44a81d"
                sizes="(max-width: 2543px) 100vw, 2543px"
                srcSet="/images/IMG_8241-p-500.jpg 500w, images/IMG_8241-p-800.jpg 800w, images/IMG_8241-p-1080.jpg 1080w, images/IMG_8241-p-1600.jpg 1600w, images/IMG_8241-p-2000.jpg 2000w, images/IMG_8241.JPG 2543w"
                alt=""
                className="af-class-image-15"
              />
              <img
                src="/images/IMG_8500.JPG"
                loading="lazy"
                id="w-node-_21fc527e-4af6-2e5e-215d-bf801c0f3aa6-cb44a81d"
                sizes="(max-width: 3021px) 100vw, 3021px"
                srcSet="/images/IMG_8500-p-500.jpg 500w, images/IMG_8500-p-800.jpg 800w, images/IMG_8500-p-1080.jpg 1080w, images/IMG_8500-p-1600.jpg 1600w, images/IMG_8500-p-2000.jpg 2000w, images/IMG_8500-p-2600.jpg 2600w, images/IMG_8500.JPG 3021w"
                alt=""
                className="af-class-image-16"
              />
              <img
                src="/images/IMG_2217.JPG"
                loading="lazy"
                id="w-node-_97cc091f-59e1-d66d-33b5-776a2988c533-cb44a81d"
                sizes="(max-width: 3054px) 100vw, 3054px"
                srcSet="/images/IMG_2217-p-500.jpg 500w, images/IMG_2217-p-800.jpg 800w, images/IMG_2217-p-1080.jpg 1080w, images/IMG_2217-p-1600.jpg 1600w, images/IMG_2217-p-2000.jpg 2000w, images/IMG_2217-p-2600.jpg 2600w, images/IMG_2217.JPG 3054w"
                alt=""
                className="af-class-image-17"
              />
              <img
                src="/images/IMG_3685.JPG"
                loading="lazy"
                id="w-node-_99b3f4b9-34b7-272e-1b09-e790bddda26c-cb44a81d"
                sizes="(max-width: 3023px) 100vw, 3023px"
                srcSet="/images/IMG_3685-p-500.jpg 500w, images/IMG_3685-p-800.jpg 800w, images/IMG_3685-p-1080.jpg 1080w, images/IMG_3685-p-1600.jpg 1600w, images/IMG_3685-p-2000.jpg 2000w, images/IMG_3685-p-2600.jpg 2600w, images/IMG_3685.JPG 3023w"
                alt=""
                className="af-class-image-18"
              />
            </div>
          </div>
          <div className="af-class-project-grid-section af-class-section-spacing-top">
            <div className="af-class-container w-container">
              <h2 className="af-class-section-title">
                {useFormatMessage("home.our-work")}
              </h2>
              <div className="af-class-collection-list-wrapper-3-copy w-dyn-list">
              {/* <ProjectsGrid locale={locale} /> */}
              <div role="list" className="af-class-project-grid w-dyn-items">
                {Projects?.map((data, idx) => (
                  <div
                    key={idx}
                    id="w-node-e0218e6c-b19a-58be-dbb1-e24fc1c375c9-3b44a7c1"
                    role="listitem"
                    className="af-class-collection-item-3 w-dyn-item"
                  >
                    <a
                      href={`project/${data.slug.data}`}
                      className="af-class-project-grid-item w-inline-block"
                    >
                      <div
                        data-w-id="e0218e6c-b19a-58be-dbb1-e24fc1c375cb"
                        className="af-class-project-grid-info-copy"
                      >
                        <img
                          src={data?.thumbnail.data.url}
                          loading="lazy"
                          data-w-id="e0218e6c-b19a-58be-dbb1-e24fc1c375cc"
                          alt=""
                          className="af-class-project-grid-image-copy af-class-project-grid-image-page-copy"
                        />
                        <div className="af-class-project-view-circle">View</div>
                      </div>
                      <div className="af-class-div-block-3">
                        <div className="af-class-project-grid-category">
                          {locale === "tr"
                            ? data.category_tr.data
                            : data.category.data}
                        </div>
                        <h4 className="af-class-project-grid-title-copy">
                          {locale === "tr" ? data.name_tr.data : data.name.data}
                        </h4>
                        <p className="af-class-project-grid-content">
                          {locale === "tr"
                            ? data.oneliner_tr.data
                            : data.oneliner.data}
                        </p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
              {/* <div
                data-delay={4000}
                data-animation="slide"
                className="af-class-project-slider w-slider"
                data-autoplay="false"
                data-easing="ease"
                data-hide-arrows="false"
                data-disable-swipe="false"
                data-autoplay-limit={0}
                data-nav-spacing={3}
                data-duration={500}
                data-infinite="true"
              >
                <div className="af-class-project-grid-mask w-slider-mask">
                  <div className="af-class-project-slide w-slide">
                    <div className="w-dyn-list">
                      <div role="list" className="w-dyn-items">
                        {Projects.map(function (data, idx) {
                          return (
                            <div
                              key={idx}
                              role="listitem"
                              className="w-dyn-item"
                            >
                              <a
                                href={`project/${data.slug.data}`}
                                className="af-class-project-grid-item w-inline-block"
                              >
                                <div
                                  data-w-id="fca5466a-967c-df56-333b-079249f46425"
                                  className="af-class-project-grid-info"
                                >
                                  <img
                                    src={data?.thumbnail.data.url}
                                    loading="lazy"
                                    alt=""
                                    className="af-class-project-grid-image"
                                  />
                                  <div className="af-class-project-view-circle">
                                    View
                                  </div>
                                </div>
                                <div className="af-class-project-grid-category">
                                  {locale == "tr"
                                    ? data.category_tr.data
                                    : data.category.data}
                                </div>
                                <h4 className="af-class-project-grid-title-copy">
                                  {locale == "tr"
                                    ? data.name.data
                                    : data.name.data}
                                </h4>
                                <p className="af-class-project-grid-content">
                                  {locale == "tr"
                                    ? data.oneliner.data
                                    : data.oneliner.data}
                                </p>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="af-class-left-arrow-top w-slider-arrow-left">
                  <img
                    src="/images/arrow-left-primary.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-arrow-icon"
                  />
                </div>
                <div className="af-class-right-arrow-top w-slider-arrow-right">
                  <img
                    src="/images/arrow-right.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-arrow-icon"
                  />
                </div>
                <div className="af-class-project-slide-nav w-slider-nav w-round" />
              </div> */}
            </div>
          </div>
          <Footer />
        </div>
      </span>
    </span>
  );
};

export default ServiceDetailView;
/* eslint-enable */
