import './styles';
import './scripts';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Router from './views/Router';
import LanguageWrapper from './components/LanguageWrapper';
import { Provider } from 'react-redux';
import store from './state/store';

import './assets/css/webflow.css';
import './assets/css/normalize.css';
import './assets/css/solo-arch.webflow.css';

console.log("Starting the application...");

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

function App() {
  console.log("Rendering the App component...");

  return (
    <Provider store={store}>
      <LanguageWrapper>
        <Router />
      </LanguageWrapper>
    </Provider>
  );
}

root.render(<App />);
console.log("App component rendered.");

reportWebVitals();
console.log("reportWebVitals called.");
