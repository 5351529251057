import React, { useEffect } from 'react';
import WebFont from 'webfontloader';

const GoogleFontsLoader = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Poppins:regular,500",
          "Noto Serif Armenian:200,300,400,500,600,700",
          "Antic Didone:regular",
          "Dosis:200,300,regular,500,600,700,800",
          "Noto Serif Hebrew:100,200,300,regular,500,600,700,800,900:hebrew,latin,latin-ext",
          "Noto Serif:regular,italic,700,700italic:latin,latin-ext",
          "Roboto:300,300italic,regular,italic,700,700italic:cyrillic,cyrillic-ext,greek,greek-ext,latin,latin-ext,vietnamese"
        ]
      }
    });
  }, []);

  return <React.Fragment />;
};

export default GoogleFontsLoader;
