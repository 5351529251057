// localeUtils.js
export const loadLocaleData = async (locale) => {
    try {
      const data = await import(`../languages/${locale}.json`);
      console.log(data)
      return data.default;
    } catch (error) {
      console.error('Error loading locale data:', error);
      return {}; // Return empty object in case of an error
    }
  };
  