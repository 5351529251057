import React from 'react';

const Footer = () => {
  return (
    <footer id="footer" className="af-class-footer af-class-wf-section">
    <div className="af-class-footer-section">
      <div className="w-layout-grid af-class-grid">
        <div className="af-class-footer-row-1">
          <a href="https://www.instagram.com/soloarchitects/" target="_blank" className="af-class-footer-link">@soloarchitects</a>
          <a href="mailto:info@mimarliksolo.com" className="af-class-footer-link">info@mimarliksolo.com</a>
          <a href="tel:+90 (212) 871 7516" className="af-class-footer-link">+90 (212) 871 7516</a>
        </div>
        <div className="af-class-footer-row-2">
          <a href="#" className="af-class-footer-link-copy">© All rights reserved 2023 &nbsp;Solo Architects</a>
        </div>
      </div>
    </div>
  </footer>
  );
};

export default Footer;
