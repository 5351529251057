import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setPreferences } from '../state/reducers/preferencesSlice';
import englishData from '../languages/en.json';
import turkishData from '../languages/tr.json';

// Set initial values for english and turkish
let english = englishData; 
let turkish = turkishData; 

export const setLanguageData = (data) => {
  [english, turkish] = data;
};

// Retrieve the language data from sessionStorage
// const localisationData = JSON.parse(sessionStorage.getItem('localisationData'));
// Commented out the above line, as it's not being used

// const localisationData = JSON.parse(localStorage.getItem('localisationData'));
// Commented out the above line, as it's not being used

// console.log('localisationData:', localisationData);

// If localisationData is not null, update the language data
// if (localisationData) {
//   setLanguageData(localisationData);
// }
// Commented out the above lines, as localisationData is not being used

console.log('en:', english);
console.log('tr:', turkish);

export const messages = {
  en: english,
  tr: turkish
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const useLanguageUtils = () => {
  const dispatch = useDispatch();
  const changeLocaleHandler = (local) => {
    dispatch(setPreferences(local));
  };
  const { locale } = useSelector(
    (state) => ({
      locale: state.preferences.locale,
    }),
    shallowEqual
  );
  const locales = availableLocales.filter((local) => local !== locale);

  return { changeLocaleHandler, locale, locales };
};
