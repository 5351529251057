/* eslint-disable */

import GoogleFontsLoader from "../utils/GoogleFontsLoader";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FsLightbox from "fslightbox-react";
import { useLanguageUtils } from "../utils/index";
import React, { useEffect, useState } from "react";
import { useFormatMessage } from "../hooks";
import { NavLinks } from "../components/NavLinks";
import Footer from "../components/Footer";
import log from "../helpers/logger";
import Loader from "../components/Loader";

const DetailProjectView = () => {
  const Projects = useSelector((state) => state.dynamicData.data?.Projects || []);

  // State to hold project data
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    // Get the path from the current URL
    const currentPath = window.location.pathname;
    const pathSegments = currentPath.split('/');
    const pageName = pathSegments[pathSegments.length - 1];

    // Find the project that matches the current path
    const matchingProject = Projects.find((Project) => Project.slug.data === pageName);
    setProjectData(matchingProject);
  }, [Projects]);

  log('Project:', projectData);

  const { changeLocaleHandler, locale } = useLanguageUtils();

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number + 1,
    });
  }

  const projectBody = `${projectData?.body.data}`;

  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2eff41c44a815";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";
  };
  componentDidMount();

  return (
    <span>
      {projectData ? (
        <>
          <GoogleFontsLoader />
          <style
            dangerouslySetInnerHTML={{
              __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/solo-arch.webflow.css);
        `,
            }}
          />
          <span className="af-view">
            <div className="af-class-body-7">
              <NavLinks
                useFormatMessage={useFormatMessage}
                locale={locale}
                changeLocaleHandler={changeLocaleHandler}
              />
              <div className="af-class-project-detail-section af-class-wf-section">
                <div className="af-class-container w-container">
                  <img
                    src={projectData.bigimage.data.url}
                    loading="eager"
                    alt=""
                    className="af-class-image-3"
                  />
                  <div className="w-layout-grid af-class-grid-project-info">
                    <div className="af-class-div-block-2">
                      <h1 className="af-class-heading-2">
                        {locale === "tr" ? projectData.name_tr.data : projectData.name.data}
                      </h1>
                    </div>
                    <div className="af-class-project-info-right">
                      <div className="af-class-featured-list">
                        <div className="af-class-featured-list-item">
                          <div className="af-class-featured-list-title">
                            Client:{" "}
                          </div>
                          <div>{projectData.client.data}</div>
                        </div>
                        <div className="af-class-featured-list-item">
                          <div className="af-class-featured-list-title">
                            Location:{" "}
                          </div>
                          <div>{projectData.location.data}</div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="af-class-rich-text-block w-richtext"
                    dangerouslySetInnerHTML={{ __html: projectBody }}
                  />
                </div>
              </div>

              {/* Project Gallery Section */}
              <div className="af-class-project-gallery af-class-wf-section">
                <div className="af-class-collection-list-wrapper w-dyn-list">
                  <div
                    role="list"
                    className="af-class-grid-project-gallery w-dyn-items"
                  >
                    {projectData.gallery.data.map((data, idx) => (
                      <div
                        key={idx}
                        role="listitem"
                        className="af-class-collection-item w-dyn-item"
                      >
                        <a
                          onClick={() => openLightboxOnSlide(idx)}
                          className="af-class-grid-project-lightbox w-inline-block w-lightbox"
                        >
                          <img
                            src={data.url}
                            loading="eager"
                            alt=""
                            className="af-class-image-4"
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* FsLightbox Implementation */}
              <FsLightbox
                toggler={lightboxController.toggler}
                sources={projectData.gallery.data.map((item) => item.url)} // Array of image URLs
                slide={lightboxController.slide}
              />

              <Footer />
            </div>
          </span>
        </>
      ) : (
        <Loader />
      )}
    </span>
  );
};

export default DetailProjectView;

/* eslint-enable */
