/* eslint-disable */


import { NavLinks } from "../components/NavLinks";
import { useFormatMessage } from "../hooks";
import { useLanguageUtils } from "../utils/index";
import React, { useEffect, useState } from "react";
// import { fetchTeamsAndProjects } from '../firebase/fetch';

import GoogleFontsLoader from "../utils/GoogleFontsLoader";
// import Team from "../data/team";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63667a86befaedf883e34757"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

const AboutView = () => {

  const Team = useSelector((state) => state.dynamicData.data?.Teams || []);  // State to hold team data

    const { changeLocaleHandler, locale } = useLanguageUtils();
  //Language Part
  // const dispatch = useDispatch();
  // const changeLocaleHandler = (local) => {
  //     dispatch(setUserLocale(local));
  // };
  // const { locale } = useSelector(
  // (state) => ({
  //     locale: state.preferences.locale,
  // }),
  // shallowEqual
  // );
  // const locales = availableLocales.filter((local) => local !== locale);
  //---------------------

  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2ef623b44a7c1";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";
  };
  componentDidMount();

  return (
    <span>
      <GoogleFontsLoader />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/solo-arch.webflow.css);

          @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"e41b3079-ffac-926c-f741-1227fc99a427\"] {-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}
        `,
        }}
      />
      <span className="af-view">
        <div className="af-class-body-4">
                      <NavLinks
              useFormatMessage={useFormatMessage}
              locale={locale}
              changeLocaleHandler={changeLocaleHandler}
            />
          <div className="af-class-about-hero-section af-class-hero-inner-spacing af-class-wf-section">
            <div className="af-class-container w-container">
              <div className="af-class-div-block-8">
                <h1 className="af-class-hero-about-title">
                  {useFormatMessage("about.about-us")}
                </h1>
              </div>
              <div className="af-class-hero-about-image-wrap">
                <img
                  src="/images/IMG_2406-copy.JPG"
                  loading="eager"
                  sizes="100vw"
                  srcSet="/images/IMG_2406-copy-p-500.jpg 500w, images/IMG_2406-copy-p-800.jpg 800w, images/IMG_2406-copy-p-1080.jpg 1080w, images/IMG_2406-copy-p-1600.jpg 1600w, images/IMG_2406-copy-p-2000.jpg 2000w, images/IMG_2406-copy-p-2600.jpg 2600w, images/IMG_2406-copy-p-3200.jpg 3200w"
                  alt=""
                  className="af-class-hero-about-image"
                />
                <div className="af-class-hero-split-bg" />
              </div>
            </div>
          </div>
          <div className="af-class-section-spacing af-class-wf-section">
            <div className="af-class-container w-container">
              <div className="w-layout-grid af-class-grid-about">
                <div id="w-node-_3c1bdf24-1e35-248a-b530-8b15aa8fe6be-4844a80d">
                  <h2 className="af-class-heading-4">
                    "Anima magis est ubi amat, quam ubi animat."
                  </h2>
                  <h1 className="af-class-heading-11-copy">
                    (The soul is more where it loves, than where it lives.)
                  </h1>
                  <div className="af-class-columns-copy-copy w-row">
                    <div className="w-col w-col-3 w-col-tiny-3">
                      <img
                        src="/images/logo_1.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-image-14-copy"
                      />
                    </div>
                    <div className="w-col w-col-9 w-col-tiny-9">
                      <div className="af-class-hero-heading-wrap-copy">
                        <div className="af-class-hero-heading-overflow">
                          <h1 className="af-class-hero-heading-copy">SOLO</h1>
                        </div>
                        <div className="af-class-hero-heading-overflow">
                          <h1 className="af-class-hero-heading-2-copy">
                            Architects
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-bde17b7f-7f86-3614-bd96-16939c50bd39-4844a80d"
                  className="af-class-div-block-6"
                >
                  <p className="af-class-paragraph-2">
                    {useFormatMessage("about.body")}
                  </p>
                  <div className="af-class-div-block-5">
                    <img
                      src="/images/logo_1.svg"
                      loading="lazy"
                      alt=""
                      className="af-class-image-14-copy"
                    />
                    <div className="af-class-hero-heading-wrap-copy">
                      <div className="af-class-hero-heading-overflow">
                        <h1 className="af-class-hero-heading-copy">SOLO</h1>
                      </div>
                      <div className="af-class-hero-heading-overflow">
                        <h1 className="af-class-hero-heading-2-copy">
                          Architects
                        </h1>
                        <h1 className="af-class-hero-heading-2-copy-copy">
                          {useFormatMessage("about.partners")}
                        </h1>{" "}
                      </div>
                    </div>
                  </div>
                  <h1 className="af-class-heading-12">
                    {useFormatMessage("about.partners")}
                  </h1>{" "}
                  <div className="w-layout-grid af-class-about-clients">
                    <div
                      id="w-node-_8b88c95c-6f88-7f40-b035-c0a000590202-4844a80d"
                      className="af-class-client-item"
                    >
                      <img
                        src="/images/IMG_1018.PNG"
                        loading="lazy"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 17vw, (max-width: 991px) 18vw, (max-width: 1279px) 16vw, 20vw"
                        srcSet="/images/IMG_1018-p-500.png 500w, images/IMG_1018-p-800.png 800w, images/IMG_1018-p-1080.png 1080w, images/IMG_1018-p-1600.png 1600w, images/IMG_1018-p-2000.png 2000w, images/IMG_1018.PNG 2048w"
                        alt=""
                        className="af-class-image-5"
                      />
                      <div>Ulteda</div>
                    </div>
                    <div
                      id="w-node-a4d3791d-1a0e-a9ed-3442-f421656db73a-4844a80d"
                      className="af-class-client-item"
                    >
                      <img
                        src="/images/Untitled_Artwork-3.png"
                        loading="lazy"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 17vw, (max-width: 991px) 18vw, (max-width: 1279px) 16vw, 20vw"
                        srcSet="/images/Untitled_Artwork-3-p-500.png 500w, images/Untitled_Artwork-3-p-800.png 800w, images/Untitled_Artwork-3-p-1080.png 1080w, images/Untitled_Artwork-3-p-1600.png 1600w, images/Untitled_Artwork-3-p-2000.png 2000w, images/Untitled_Artwork-3.png 2048w"
                        alt=""
                        className="af-class-image-8"
                      />
                      <div>Altındar Mermer</div>
                    </div>
                    <div
                      id="w-node-_5672ed10-d2af-f1b8-da97-f2882b6811d4-4844a80d"
                      className="af-class-client-item"
                    >
                      <img
                        src="/images/Untitled_Artwork-2.png"
                        loading="lazy"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 17vw, (max-width: 991px) 18vw, (max-width: 1279px) 16vw, 20vw"
                        srcSet="/images/Untitled_Artwork-2-p-500.png 500w, images/Untitled_Artwork-2-p-800.png 800w, images/Untitled_Artwork-2-p-1080.png 1080w, images/Untitled_Artwork-2-p-1600.png 1600w, images/Untitled_Artwork-2-p-2000.png 2000w, images/Untitled_Artwork-2.png 2048w"
                        alt=""
                        className="af-class-image-8"
                      />
                      <div>Cemus Mobilya</div>
                    </div>
                    <div
                      id="w-node-ffc7cf60-6864-76b1-2f3d-9dd87002bd8a-4844a80d"
                      className="af-class-client-item"
                    >
                      <img
                        src="/images/RonnoLogo.svg"
                        loading="lazy"
                        alt=""
                        className="af-class-image-8"
                      />
                      <div>Ronno Studio</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-team-section af-class-section-spacing af-class-wf-section">
            <div className="af-class-container w-container">
              <h2 className="af-class-heading-9">Meet the artists</h2>
              <div className="w-dyn-list">
                <div role="list" className="af-class-team-list w-dyn-items">
                  {Team &&
                    Team?.map((data, idx) => (
                      <div key={idx} role="listitem" className="w-dyn-item">
                        <a
                          data-w-id="a94c0481-ed6a-645f-0fdb-abe1055cb5ff"
                          href={`team-member/${data.slug.data}`}
                          className="af-class-team-item w-inline-block"
                        >
                          <img
                            src={data.thumbnail.data.url}
                            loading="eager"
                            data-w-id="a94c0481-ed6a-645f-0fdb-abe1055cb600"
                            alt=""
                            className="af-class-team-image"
                          />
                          <h6 className="af-class-team-name">{data.name.data}</h6>
                          <div className="af-class-team-job-title">
                            {locale === "tr" ? data.jobtitle_tr.data : data.jobtitle.data}
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </span>
    </span>
  );
};

export default AboutView;

/* eslint-enable */
