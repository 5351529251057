import { createSlice } from '@reduxjs/toolkit';
import { loadLocaleData } from '../../utils/localeUtils';

const localeSlice = createSlice({
  name: 'locale',
  initialState: 'en', // Default locale
  reducers: {
    setLocale: (state, action) => {
      console.log('setLocale reducer triggered with payload:', action.payload);
      return action.payload;
    },
  },
});

export const { setLocale } = localeSlice.actions;

// Async action to fetch locale data and update the locale state
export const fetchLocaleData = (locale) => async (dispatch) => {
  try {
    console.log(`Fetching locale data for ${locale}...`);
    // Assuming loadLocaleData fetches and returns the locale data
    const data = await loadLocaleData(locale);
    dispatch(setLocale(locale)); // Update the locale in the Redux store
    console.log(`Locale data loaded successfully for ${locale}.`);
  } catch (error) {
    console.error(`Error loading locale data for ${locale}:`, error);
    // You can dispatch an error action here or perform other error handling
  }
};

// Initial loading of locale data when the app starts
export const loadInitialLocaleData = () => async (dispatch, getState) => {
  const { locale } = getState(); // Get the current locale from the Redux store
  if (locale) {
    dispatch(fetchLocaleData(locale)); // Fetch and set locale data
  }
};

export default localeSlice.reducer;
