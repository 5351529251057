/* eslint-disable */

import GoogleFontsLoader from "../utils/GoogleFontsLoader";

import { useLanguageUtils } from "../utils/index";
import React, { useEffect, useState } from "react";
import { useFormatMessage } from "../hooks";
import { NavLinks } from "../components/NavLinks";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63667a86befaedf883e34757"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

const ProjectsView = () => {
  const Projects = useSelector((state) => state.dynamicData.data?.Projects || []);

  // Helper function to check if featured data is valid
  const isFeatured = (data) => {
    // If featured exists and is boolean true, prioritize it; otherwise, treat as false
 // Normalize data.featured.data by trimming whitespace and converting to lowercase
 return data?.featured?.data?.toString().trim().toLowerCase() === 'true';  
};

  // Clone the array to avoid modifying the original one
  const clonedProjects = [...Projects];

  // Sort the cloned projects by featured status
  const sortedProjects = clonedProjects.sort((a, b) => {
    const aFeatured = isFeatured(a) ? 1 : 0; // 1 for true, 0 for false or invalid
    const bFeatured = isFeatured(b) ? 1 : 0;
    return bFeatured - aFeatured; // Featured projects will come first
  });

  // Split the sorted projects into even and odd arrays
  const evenProjects = sortedProjects.filter((_, idx) => idx % 2 === 0);
  const oddProjects = sortedProjects.filter((_, idx) => idx % 2 !== 0);

  const [toggler, setToggler] = useState(false);

  const { changeLocaleHandler, locale } = useLanguageUtils();

  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2ef623b44a7c1";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";
  };
  componentDidMount();

  return (
    <span>
      <GoogleFontsLoader />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/solo-arch.webflow.css);

          .project-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.4);
            color: white;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          .project-container:hover .project-overlay {
            opacity: 1;
          }

          .project-container {
            position: relative;
            width: 100%;
          }

          .project-title {
            font-size: 1.5rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
          }

          .project-description {
            font-size: 1rem;
            margin-bottom: 0.5rem;
          }

          .project-location {
            font-size: 0.9rem;
            font-style: italic;
          }
        `,
        }}
      />
      <span className="af-view">
        <div className="af-class-body-2">
          <NavLinks
            useFormatMessage={useFormatMessage}
            locale={locale}
            changeLocaleHandler={changeLocaleHandler}
          />
          <h1 className="af-class-heading-8">
            {useFormatMessage("home.our-work")}
          </h1>
          <section className="af-class-projects-section af-class-wf-section">
            <div className="af-class-list-left w-dyn-list">
              <div
                role="list"
                className="af-class-collection-list-wrtwrt w-dyn-items"
              >
                {evenProjects.map(function (data, idx) {
                  return (
                    <div
                      role="listitem"
                      className="af-class-collection-item-4-copy w-dyn-item project-container"
                      key={idx}
                    >
                      <a href={`project/${data.slug.data}`}>
                        <img
                          alt="image"
                          src={data.thumbnail.data.url}
                          loading="lazy"
                          className="af-class-project-img"
                        />
                        <div className="project-overlay">
                          <div className="project-title">
                            {data.name.data}
                          </div>
                          <div className="project-description">
                            {data.oneliner.data}
                          </div>
                          <div className="project-location">
                            {data.location.data}
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="af-class-gap" />
            <div className="af-class-list-left-copy w-dyn-list">
              <div
                role="list"
                className="af-class-collection-list-wrtwrt w-dyn-items"
              >
                {oddProjects.map(function (data, idx) {
                  return (
                    <div
                      role="listitem"
                      className="af-class-collection-item-4-copy w-dyn-item project-container"
                      key={idx}
                    >
                      <a href={`project/${data.slug.data}`}>
                        <img
                          alt="image"
                          src={data.thumbnail.data.url}
                          loading="lazy"
                          className="af-class-project-img"
                        />
                        <div className="project-overlay">
                          <div className="project-title">
                            {data.name.data}
                          </div>
                          <div className="project-description">
                            {data.oneliner.data}
                          </div>
                          <div className="project-location">
                            {data.location.data}
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </span>
    </span>
  );
};

export default ProjectsView;

/* eslint-enable */
