import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import en from '../../languages/en.json';
import tr from '../../languages/tr.json'; // Import the Turkish messages

const fallbackMessages = en;

const LanguageWrapper = ({ children }) => {
  const locale = useSelector((state) => state.preferences.locale);

  // Define your messages based on the locale
  const messages = locale === 'tr' ? tr : en;

  // Handle errors if translation messages are not loaded properly
  if (!messages || Object.keys(messages).length === 0) {
    console.error(`Translation messages for locale '${locale}' are missing or not loaded.`);
    // Use the fallbackMessages as the default messages
    return (
      <IntlProvider locale={locale} messages={fallbackMessages} onError={(error) => console.error(error)}>
        {children}
      </IntlProvider>
    );
  }

  return (
    <IntlProvider locale={locale || 'en'} messages={messages} onError={(error) => console.error(error)}>
      {children}
    </IntlProvider>
  );
};

export default LanguageWrapper;




// import React, { useEffect } from 'react';
// import { IntlProvider } from 'react-intl';
// import { useSelector, useDispatch } from 'react-redux';
// import { setLocale } from '../../state/reducers/localeSlice'; // Update the import path
// import { messages } from '../../utils'; // Make sure you have your messages object

// const LanguageWrapper = ({ children }) => {
//   const dispatch = useDispatch();
//   const locale = useSelector((state) => state.locale);

//   // Set locale based on browser or default
//   useEffect(() => {
//     const browserLocale = navigator.language.split('-')[0];
//     const defaultLocale = 'en';
//     const availableLocales = ['en', 'tr']; // Update with your available locales

//     if (!locale) {
//       if (availableLocales.includes(browserLocale)) {
//         dispatch(setLocale(browserLocale));
//       } else {
//         dispatch(setLocale(defaultLocale));
//       }
//     }
//   }, [dispatch, locale]);

//   // Handle missing translations
//   const localeMessages = messages[locale] || messages['en'];

//   return (
//     <IntlProvider
//       locale={locale}
//       defaultLocale="en"
//       messages={localeMessages}
//     >
//       {children}
//     </IntlProvider>
//   );
// };

// export default LanguageWrapper;
