/* eslint-disable */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicData } from "../../state/reducers/DynamicDataSlice";
export const useDynamicData = async () => {
    const dispatch = useDispatch()
    const dynamicData = useSelector(state => state.dynamicData)
    console.log(dynamicData)
    // useEffect(() => {
    //     // Use the CORS Anywhere proxy to bypass CORS issues
    //     const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    //     const targetUrl = 'https://us-central1-mimarliksolo.cloudfunctions.net/getProjects';

    //     fetch(proxyUrl + targetUrl, {
    //         method: 'GET',
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             console.log('data', data);
    //             const transformedData = {};

    //             data.forEach((obj) => {
    //                 if (!transformedData[obj.collection]) {
    //                     transformedData[obj.collection] = [];
    //                 }
    //                 transformedData[obj.collection] = transformedData[obj.collection].concat(obj.items);
    //             });

    //             console.log('transformed', transformedData);

    //             dispatch(setDynamicData(transformedData));
    //         })
    //         .catch((error) => console.error(error));
    // }, []);
    useEffect(() => {

        // if(dynamicData.data != null){
        //     console.log("Data found",dynamicData);

        // }
        // else {
        fetch('https://us-central1-mimarliksolo.cloudfunctions.net/getProjects', {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                console.log("data", data)
                const transformedData = {};

                data.forEach(obj => {
                    if (!transformedData[obj.collection]) {
                        transformedData[obj.collection] = [];
                    }
                    transformedData[obj.collection] = transformedData[obj.collection].concat(obj.items);
                });

                console.log("transformed", transformedData);

                dispatch(setDynamicData(transformedData))
            })

            .catch(error => console.error(error));
        // };
    }, []);
};