import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ContactView,
  DetailProjectView,
  DetailTeamView,
  NotFoundView,
  AboutView,
  IndexView,
  ProjectsView,
  ServiceDetailView,
  // DetailProductView,
} from "..";

import paths from "./paths";
import { useDynamicData } from "../../helpers/hooks/UseDynamicData";

const RouterComponent = () => {
  // Fetch dynamic data from Redux store
  const dynamicData = useSelector((state) => state.dynamicData.data);
  const projectData = dynamicData?.Projects;
  const teamData = dynamicData?.Teams;

  // Fetch localisation data from Redux store
  const localisation = useSelector((state) => state.dynamicData.localisation);

  // Log when RouterComponent is being rendered
  console.log("RouterComponent rendered");

  // Fetch dynamic data (Projects, Teams, etc.) using a custom hook
  useDynamicData();

  return (
    <BrowserRouter forceRefresh={true}>
      <Routes>
        <Route exact path={paths.ROOT} element={<IndexView />} />
        <Route exact path={paths.ABOUT} element={<AboutView />} />
        <Route exact path={paths.PROJECTS} element={<ProjectsView />} />

        {/* Render each project detail page */}
        {projectData &&
          projectData.map((page, index) => (
            <Route
              key={index}
              path={`project/${page.slug?.data}`}
              element={<DetailProjectView content={page} />}
              exact
            />
          ))}

        <Route path={paths.PROJECT} element={<DetailProjectView />} />
        {/* <Route exact path={paths.PRODUCT} element={<DetailProductView />} /> */}
        <Route exact path={paths.CONTACT} element={<ContactView />} />
        <Route exact path={paths.SERVICES} element={<ServiceDetailView />} />
        <Route path={paths.TEAMMEMBER} element={<DetailTeamView />} />

        <Route element={<NotFoundView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
