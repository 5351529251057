// store.js
import { configureStore } from '@reduxjs/toolkit';
import DynamicDataSlice from './reducers/DynamicDataSlice';
import preferencesSlice from './reducers/preferencesSlice';
import localeSlice from './reducers/localeSlice';

console.log('store.js - Before configureStore');

const store = configureStore({
  reducer: {
    dynamicData: DynamicDataSlice,
    preferences: preferencesSlice,
    locale: localeSlice,
  },
});

console.log('Initial store state:', store.getState()); // Add this line

console.log('store.js - After configureStore');

export default store;
