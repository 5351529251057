/* eslint-disable */

import React, { useEffect, useState } from "react";
// import { fetchTeamsAndProjects } from '../firebase/fetch';
import GoogleFontsLoader from "../utils/GoogleFontsLoader";
import FsLightbox from "fslightbox-react";
import { useLanguageUtils } from "../utils/index";
import { useFormatMessage } from "../hooks";
// import Projects from '../data/projects'
// // import Team from "../data/team";
import Footer from "../components/Footer";
// import { fetchCollectionData } from '../firebase/fetch';
// import { db } from "../firebase/index";
// import { collection, getDocs } from "firebase/firestore";
import { NavLinks } from "../components/NavLinks";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocaleData } from "../state/reducers/localeSlice";
// import { fetchDataFromFirestore } from "../firebase/api";
// import { ReactSession } from 'react-client-session';
// import { setUserLocale } from "../state/actions/preferences";
// import { availableLocales } from "../utils";
// import ReloadOnLoad from "../utils/ReloadOnLoad";
// import ProjectsGrid from "../helpers/ProjectsGrid";
// import dynamicDataReducer from "../state/reducers/DynamicData";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63667a86befaedf883e34757"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

const IndexView = () => {
  const dispatch = useDispatch();
  const activeLocale = useSelector((state) => state.locale);
  const localeData = useSelector((state) => state.localeData);

  useEffect(() => {
    dispatch(fetchLocaleData(activeLocale)); // Dispatch the async action to fetch locale data
  }, [dispatch, activeLocale]);

  // const Projects = [];
  // const Team = [];

  // const dispatch = useDispatch();

  // useEffect(() => {
  //   // Fetch data from Firestore when the component mounts
  //   dispatch(fetchDataFromFirestore('Teams'));
  //   dispatch(fetchDataFromFirestore('Projects'));
  // }, [dispatch]);

  // const Team = JSON.parse(sessionStorage.getItem("TeamsData"));
  // const Projects = JSON.parse(sessionStorage.getItem("ProjectsData"));
  const Team = useSelector((state) => state.dynamicData.data?.Teams || []);
  const Projects = useSelector(
    (state) => state.dynamicData.data?.Projects || []
  );

  // Fetch team data when the component mounts
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(dynamicDataReducer()); // Dispatch the action to fetch team data
  // }, [dispatch]);

  //   const [teamData, setTeamData] = useState(Team || []);
  //   const [projectsData, setProjectsData] = useState(Projects || []);

  //   useEffect(() => {
  //     // Custom event listener
  //     const handleDataUpdated = (event) => {
  //       const { storageKey } = event.detail;
  //       const updatedData = JSON.parse(sessionStorage.getItem(storageKey));
  //       if (updatedData) {
  //         if (storageKey === "TeamsData") {
  //           setTeamData(updatedData);
  //         } else if (storageKey === "ProjectsData") {
  //           setProjectsData(updatedData);
  //         }
  //         // You can add more conditions here for other storage keys
  //       }
  //     };

  //     // Attach event listener to the window
  //     window.addEventListener("dataUpdated", handleDataUpdated);

  //     // Clean up the event listener when the component unmounts
  //     return () => {
  //       window.removeEventListener("dataUpdated", handleDataUpdated);
  //     };
  //   }, []);

  //   // Custom event listener
  // const handleDataUpdated = (event) => {
  //   console.log("Custom event received:", event.detail);
  //   // Rest of the code
  // };

  // // Dispatch a custom event
  // const projectsEvent = new CustomEvent("dataUpdated", {
  //   detail: { collectionName: "ProjectsData" }
  // });
  // console.log("Dispatching custom event");
  // window.dispatchEvent(projectsEvent);

  // Access the fetched data from the Redux store using useSelector
  // const Team = useSelector((state) => state.TeamsData); // Replace 'teams' with the name of your teams data reducer
  // const Projects = useSelector((state) => state.ProjectsData); // Replace 'projects' with the name of your projects data reducer
  // console.log(Projects,Team)

  const [toggler, setToggler] = useState(false);

  const json = {
    items: [
      {
        url: "https://youtube.com/watch?v=djONm0TbUoA",
        originalUrl: "https://youtube.com/watch?v=djONm0TbUoA",
        width: 940,
        height: 528,
        thumbnailUrl: "https://i.ytimg.com/vi/djONm0TbUoA/hqdefault.jpg",
        html:
          '<iframe className="embedly-embed" src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FdjONm0TbUoA%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DdjONm0TbUoA&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FdjONm0TbUoA%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube" width="940" height="528" scrolling="no" title="YouTube embed" frameborder="0" allow="autoplay; fullscreen" allowFullScreen="allowFullScreen" allowFullScreen></iframe>',
        type: "video",
      },
    ],
    group: "",
  };

  const { changeLocaleHandler, locale } = useLanguageUtils();
  //Language Part
  // const dispatch = useDispatch();
  // const changeLocaleHandler = (local) => {
  //     dispatch(setUserLocale(local));
  // };
  // const { locale } = useSelector(
  // (state) => ({
  //     locale: state.preferences.locale,
  // }),
  // shallowEqual
  // );
  // const locales = availableLocales.filter((local) => local !== locale);
  //---------------------
  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2ef623b44a7c1";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  };
  componentDidMount();

  return (
    <span>
      {/* <ReloadOnLoad /> */}
      <GoogleFontsLoader />
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @import url(/css/normalize.css);
        @import url(/css/webflow.css);
        @import url(/css/solo-arch.webflow.css);

        @media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a94c0481-ed6a-645f-0fdb-abe1055cb600\"] {-webkit-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);}}
      `,
        }}
      />
      <span className="af-view">
        <div className="af-class-body">
          <NavLinks
            useFormatMessage={useFormatMessage}
            locale={locale}
            changeLocaleHandler={changeLocaleHandler}
          />

          {/* <section className="hero-light background">
            <div className="hero-light-div">
              <div className="image-container" style={{ position: "relative" }}>
                <img
                  src="/images/L_SAE.svg"
                  loading="lazy"
                  alt=""
                  style={{ zIndex: "99", width: "50rem" }}
                />
                <a
                  data-w-id="a9130bb1-89da-27ac-9808-07a670fe8eb2"
                  href="https://studiolsae.com/"
                  style={{
                    color: "rgb(255, 255, 255)",
                    backgroundColor: "rgb(0, 0, 0)",
                    pointerEvents: "none",
                    position: "absolute",
                    bottom: "0",
                    left: "50%", // You can adjust the left value to center the text
                    transform: "translateX(-50%)", // Center the text horizontally
                  }}
                >
                  Coming soon...
                </a>
              </div>
              <div className="div-block-10">
                <div className="div-block-coffffpy">
                  <div className="text-block-10">by </div>
                  <a
                    href="/"
                    aria-current="page"
                    className="brand-copy w-nav-brand w--current"
                  >
                    <div className="text-block-copy-copy">SOLO</div>
                    <div className="text-block-2-copy-copy">ARCHITECTS</div>
                  </a>
                </div>
              </div>
            </div>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </section> */}

          {/* <section className="af-class-hero-light af-class-wf-section">
            <div className="af-class-hero-light-div">
              <div className="af-class-text-block-11">Arvei</div>
              <div className="af-class-div-block-10">
                <div className="af-class-div-block-coffffpy">
                  <div className="af-class-text-block-10">by </div>
                  <a
                    href="/"
                    aria-current="page"
                    className="af-class-brand-copy w-nav-brand w--current"
                  >
                    <img
                      src="/images/logo_1.svg"
                      loading="lazy"
                      data-w-id="daccccc5-0ffb-b6c0-50d8-deade652bfed"
                      alt=""
                      className="af-class-image-copyrtert"
                    />
                    <div className="af-class-text-block-copy-copy">SOLO</div>
                    <div className="af-class-text-block-2-copy-copy">
                      ARCHITECTS
                    </div>
                  </a>
                </div>
              </div>
              <a
                data-w-id="a9130bb1-89da-27ac-9808-07a670fe8eb2"
                href="/product/arvei"
                className="af-class-intro-link"
              >
                Purchase
              </a>
            </div>
          </section> */}
          <div
            data-w-id="2b88efbc-a95a-4945-4975-155353eb2c89"
            className="af-class-hero-section af-class-wf-section"
          >
            <div className="af-class-container w-container">
              <div className="af-class-hero-wrap">
                <div className="af-class-div-block-4">
                  <img
                    src="/images/ARROW-Model.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-image-13"
                  />
                </div>
                <div className="af-class-hero-heading-wrap">
                  <div className="af-class-hero-heading-overflow">
                    <h1 className="af-class-hero-heading">SOLO</h1>
                  </div>
                  <div className="af-class-decoration-shape">
                    <img
                      src="/images/hero-decation-01.png"
                      loading="lazy"
                      alt=""
                      className="af-class-heor-decoration-image"
                    />
                  </div>
                  <div className="af-class-hero-heading-overflow">
                    <h1 className="af-class-hero-heading-2">Architects</h1>
                  </div>
                </div>
                <div className="af-class-hero-image-wrap">
                  <div className="af-class-hero-image-block">
                    <img
                      src="/images/logo.svg"
                      loading="eager"
                      alt=""
                      className="af-class-hero-image-3"
                    />
                    <img
                      src="/images/logo_1.svg"
                      loading="eager"
                      alt=""
                      className="af-class-hero-image-2"
                    />
                    <img
                      src="/images/hero-image.jpg"
                      loading="eager"
                      alt=""
                      className="af-class-hero-image-1"
                    />
                  </div>
                  <div className="af-class-decoration-blur" />
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-about-section af-class-section-spacing-bottom af-class-wf-section">
            <div className="af-class-container w-container">
              <div className="af-class-about-wrap">
                <p className="af-class-about-subtitle">
                  We are solo architects
                </p>
                <h4 className="af-class-about-heading">
                  "Anima magis est ubi amat, quam ubi animat."
                </h4>
                <img
                  src="/images/hero-decation-02_1hero-decation-02.png"
                  loading="lazy"
                  alt=""
                  className="af-class-about-shape-decoration"
                />
                <p>{useFormatMessage("home.about-us")}</p>
                <div className="af-class-about-links">
                  <a
                    href="/about"
                    className="af-class-about-link-item w-inline-block"
                  >
                    <div>{useFormatMessage("home.more-about-us")}</div>
                  </a>
                  <p className="af-class-about-link-divider">|</p>
                  <a
                    href="/projects"
                    className="af-class-about-link-item w-inline-block"
                  >
                    <div>{useFormatMessage("home.view-our-work")}</div>
                  </a>
                  <p className="af-class-about-link-divider">|</p>
                  <a
                    href="/contact"
                    className="af-class-about-link-item w-inline-block"
                  >
                    <div>{useFormatMessage("home.contact-us")}</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="af-class-expertise-section af-class-wf-section">
            <div className="w-layout-grid af-class-grid-expertise">
              <div
                id="w-node-ec9b35e8-76f3-60e5-616b-4d128dccc6af-3b44a7c1"
                className="af-class-expertise-item"
              >
                <div className="af-class-expertise-hover">
                  <img
                    src="/images/icon-1.svg"
                    loading="lazy"
                    alt=""
                    className="af-class-expertise-icon"
                  />
                  <h3 className="af-class-expertise-title">
                    {useFormatMessage("home.architectural-services-H1")}
                  </h3>
                  <p className="af-class-expertise-description">
                    {useFormatMessage("home.architectural-services")}
                  </p>
                  <a
                    href="service-detail"
                    className="af-class-button-white af-class-expertise-button w-button"
                  >
                    {useFormatMessage("home.read-more")}
                  </a>{" "}
                </div>
                <img
                  src="/images/f163e0d2-eabf-4317-b1ed-78066c24a44b.JPG"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 69vw, (max-width: 991px) 39vw, (max-width: 1279px) 91vw, 99vw"
                  srcSet="/images/f163e0d2-eabf-4317-b1ed-78066c24a44b-p-500.jpg 500w, images/f163e0d2-eabf-4317-b1ed-78066c24a44b-p-800.jpg 800w, images/f163e0d2-eabf-4317-b1ed-78066c24a44b-p-1080.jpg 1080w, images/f163e0d2-eabf-4317-b1ed-78066c24a44b.JPG 1638w"
                  alt=""
                  className="af-class-expertise-image"
                />
                <div className="af-class-expertise-bg-color" />
              </div>
              <div
                id="w-node-d93c76f9-1255-5303-292e-65824790597b-3b44a7c1"
                className="af-class-expertise-item"
              >
                <div className="af-class-expertise-hover">
                  <img
                    src="/images/icon-2.svg"
                    loading="lazy"
                    alt="image"
                    className="af-class-expertise-icon"
                  />
                  <h3 className="af-class-expertise-title">
                    {useFormatMessage("home.interior-services-H1")}
                  </h3>
                  <p className="af-class-expertise-description">
                    {useFormatMessage("home.interior-design-services")}
                  </p>
                  <a
                    href="service-detail"
                    className="af-class-button-white af-class-expertise-button w-button"
                  >
                    {useFormatMessage("home.read-more")}
                  </a>
                </div>
                <img
                  src="/images/Niyama-Mutfak-copy.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 44vw, (max-width: 991px) 31vw, (max-width: 1279px) 84vw, 98vw"
                  srcSet="/images/Niyama-Mutfak-copy-p-500.jpg 500w, images/Niyama-Mutfak-copy-p-800.jpg 800w, images/Niyama-Mutfak-copy-p-1080.jpg 1080w, images/Niyama-Mutfak-copy.jpg 1920w"
                  alt="image"
                  className="af-class-expertise-image"
                />
                <div className="af-class-expertise-bg-color" />
              </div>
              <div
                id="w-node-_1a94cc3d-0a50-dd19-93cd-58b480c0213d-3b44a7c1"
                className="af-class-expertise-item"
              >
                <div className="af-class-expertise-hover">
                  <img
                    src="/images/icon-3.svg"
                    loading="lazy"
                    alt="image"
                    className="af-class-expertise-icon"
                  />
                  <h3 className="af-class-expertise-title">
                    {useFormatMessage("home.landscaping-services-H1")}
                  </h3>
                  <p className="af-class-expertise-description">
                    {useFormatMessage("home.landscape-design-services")}
                  </p>
                  <a
                    href="service-detail"
                    className="af-class-button-white af-class-expertise-button w-button"
                  >
                    {useFormatMessage("home.read-more")}
                  </a>
                </div>
                <img
                  src="/images/IMG_8756.jpg"
                  loading="lazy"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 44vw, (max-width: 991px) 31vw, (max-width: 1279px) 84vw, 98vw"
                  srcSet="/images/IMG_8756-p-500.jpg 500w, images/IMG_8756-p-800.jpg 800w, images/IMG_8756-p-1080.jpg 1080w, images/IMG_8756-p-1600.jpg 1600w, images/IMG_8756-p-2000.jpg 2000w, images/IMG_8756.jpg 3024w"
                  alt="image"
                  className="af-class-expertise-image"
                />
                <div className="af-class-expertise-bg-color" />
              </div>
            </div>
          </div>
          <div className="af-class-tiny-section af-class-wf-section">
            <a href="#" className="af-class-link-block w-inline-block">
              <div className="af-class-hero-heading-wrap">
                <div className="af-class-hero-heading-overflow">
                  <h1 className="af-class-hero-heading">SOLO</h1>
                </div>
                <div className="af-class-hero-heading-overflow">
                  <h1 className="af-class-hero-heading-2">Tiny</h1>
                </div>
              </div>
            </a>
            <a
              // TODO: remove
              // href="/project/solo-tiny"
              id="w-node-_2a8d0037-3b89-8450-aeb4-17b2e830d0f9-3b44a7c1"
              className="af-class-link"
            >
              {useFormatMessage("home.learn-more")}
            </a>{" "}
          </div>
          <div className="af-class-container-20 w-container">
            <h1 className="af-class-heading-3">
              {useFormatMessage("home.our-work")}
            </h1>
            <div className="af-class-collection-list-wrapper-3-copy w-dyn-list">
              {/* <ProjectsGrid locale={locale} /> */}
              <div role="list" className="af-class-project-grid w-dyn-items">
                {Projects?.map((data, idx) => (
                  <div
                    key={idx}
                    id="w-node-e0218e6c-b19a-58be-dbb1-e24fc1c375c9-3b44a7c1"
                    role="listitem"
                    className="af-class-collection-item-3 w-dyn-item"
                  >
                    <a
                      href={`project/${data.slug.data}`}
                      className="af-class-project-grid-item w-inline-block"
                    >
                      <div
                        data-w-id="e0218e6c-b19a-58be-dbb1-e24fc1c375cb"
                        className="af-class-project-grid-info-copy"
                      >
                        <img
                          src={data?.thumbnail.data.url}
                          loading="lazy"
                          data-w-id="e0218e6c-b19a-58be-dbb1-e24fc1c375cc"
                          alt=""
                          className="af-class-project-grid-image-copy af-class-project-grid-image-page-copy"
                        />
                        <div className="af-class-project-view-circle">View</div>
                      </div>
                      <div className="af-class-div-block-3">
                        <div className="af-class-project-grid-category">
                          {locale === "tr"
                            ? data.category_tr.data
                            : data.category.data}
                        </div>
                        <h4 className="af-class-project-grid-title-copy">
                          {locale === "tr" ? data.name_tr.data : data.name.data}
                        </h4>
                        <p className="af-class-project-grid-content">
                          {locale === "tr"
                            ? data.oneliner_tr.data
                            : data.oneliner.data}
                        </p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="af-class-team-section af-class-section-spacing af-class-wf-section">
            <div className="af-class-container w-container">
              <h2 className="af-class-heading-9">Meet the artists</h2>
              <div className="w-dyn-list">
                <div role="list" className="af-class-team-list w-dyn-items">
                  {Team &&
                    Team?.map((data, idx) => (
                      <div key={idx} role="listitem" className="w-dyn-item">
                        <a
                          data-w-id="a94c0481-ed6a-645f-0fdb-abe1055cb5ff"
                          href={`team-member/${data.slug.data}`}
                          className="af-class-team-item w-inline-block"
                        >
                          <img
                            src={data.thumbnail.data.url}
                            loading="eager"
                            data-w-id="a94c0481-ed6a-645f-0fdb-abe1055cb600"
                            alt=""
                            className="af-class-team-image"
                          />
                          <h6 className="af-class-team-name">
                            {data.name.data}
                          </h6>
                          <div className="af-class-team-job-title">
                            {locale === "tr"
                              ? data.jobtitle_tr.data
                              : data.jobtitle.data}
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div
            data-w-id="cba28d2e-f48a-787d-28c8-a8d07cc23e83"
            className="af-class-video-bg-section af-class-wf-section"
          >
            <a
              onClick={() => setToggler(!toggler)}
              href="https://youtube.com/watch?v=djONm0TbUoA"
              className="af-class-video-lightbox w-inline-block w-lightbox"
            >
              <h2 className="af-class-play-video-text">
                {useFormatMessage("home.play")}
              </h2>
            </a>
            <FsLightbox
              toggler={toggler}
              sources={["https://www.youtube.com/watch?v=djONm0TbUoA"]}
            />
            <p className="af-class-video-description">Söğüt Competition</p>
          </div>
          {/* <div className="af-class-newsletter-section af-class-wf-section">
            <div className="af-class-container w-container">
              <div className="af-class-newsletter-wrap">
                <div className="w-layout-grid af-class-grid-newsletter">
                  <div id="w-node-fdc57d25-621f-fd68-b5dc-57d18a600c85-3b44a7c1">
                    <h4 className="af-class-text-white">
                      {useFormatMessage("footer.upcoming")}
                    </h4>{" "}
                  </div>
                  <div id="w-node-_1abfc1b8-65b5-a103-5e7f-0e1b72bd8ea5-3b44a7c1">
                    <div className="af-class-subscribe-form w-form">
                      <form
                        id="wf-form-Subscribe-Form"
                        name="wf-form-Subscribe-Form-3"
                        data-name="Subscribe Form"
                        method="get"
                        className="af-class-subscribe-form-inputs"
                        data-wf-page-id="6377c735e0b2ef623b44a7c1"
                        data-wf-element-id="08c360af-c994-34ad-e12a-e78824de3921"
                      >
                        <input
                          type="email"
                          className="af-class-form-input af-class-form-input-newsletter w-input"
                          maxLength={256}
                          name="Subscribe-Email-Footer"
                          data-name="Subscribe Email Footer"
                          placeholder={useFormatMessage(
                            "footer.enter-your-e-mail"
                          )}
                          id="Subscribe-Email-Footer"
                          required
                        />
                        <input
                          type="submit"
                          defaultValue="Subscribe!"
                          data-wait="Please wait..."
                          className="af-class-button af-class-button-subscribe w-button"
                        />
                      </form>
                      <div className="af-class-success-message w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div className="af-class-error-message w-form-fail">
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src="/images/Niyama-Mutfak-copy.jpg"
                  loading="eager"
                  alt=""
                  className="af-class-newsletter-bg"
                />
              </div>
            </div>
          </div> */}
          <Footer />
        </div>
      </span>
    </span>
  );
};

export default IndexView;

/* eslint-enable */
