/* eslint-disable */

import React from 'react'
import GoogleFontsLoader from "../utils/GoogleFontsLoader";
import { transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63667a86befaedf883e34757"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class NotFoundView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/NotFoundController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = NotFoundView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2efac8a44a7fa";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";
  }

  render() {
    const proxies =
      NotFoundView.Controller !== NotFoundView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <GoogleFontsLoader />
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/solo-arch.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="af-class-utility-page-wrap">
              <div className="af-class-utility-page-content">
                <h1 className="af-class-not-found-404">404</h1>
                <h3>Page Not Found</h3>
                <div>
                  The page you are looking for doesn't exist or has been moved
                </div>
              </div>
            </div>
          </div>
        </span>
      </span>
    );
  }
}

export default NotFoundView;

/* eslint-enable */
