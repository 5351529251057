/* eslint-disable */


import GoogleFontsLoader from "../utils/GoogleFontsLoader";
import { useLanguageUtils } from "../utils/index";
import React, { useEffect, useState } from "react";
import { useFormatMessage } from "../hooks";
import { NavLinks } from "../components/NavLinks";

const ContactView = () => {

  const { changeLocaleHandler, locale } = useLanguageUtils();

  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2ef623b44a7c1";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";
  };
  componentDidMount();

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const formData = {
      name: e.target.name.value,
      company: e.target.company.value,
      phone: e.target.phone.value,
      email: e.target.email.value,
      message: e.target.message.value,
    };
  
    fetch('https://us-central1-mimarliksolo.cloudfunctions.net/api/postSubmitForm', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.status === 200) {
          showSuccessMessage();
        } else {
          showErrorMessage();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        showErrorMessage();
      });
  };

  const showSuccessMessage = () => {
    const successMessage = document.querySelector('.af-class-success-message');
    successMessage.style.display = 'block';

    const errorMessage = document.querySelector('.af-class-error-message');
    errorMessage.style.display = 'none';
  };

  const showErrorMessage = () => {
    const errorMessage = document.querySelector('.af-class-error-message');
    errorMessage.style.display = 'block';

    const successMessage = document.querySelector('.af-class-success-message');
    successMessage.style.display = 'none';
  };

  return (
    <span>
      <GoogleFontsLoader />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/solo-arch.webflow.css);
        `,
        }}
      />
      <span className="af-view">
        <div className="af-class-body-6">
                      <NavLinks
              useFormatMessage={useFormatMessage}
              locale={locale}
              changeLocaleHandler={changeLocaleHandler}
            />
          <div className="af-class-hero-inner-spacing-fuuuuuuuu af-class-wf-section">
            <div className="af-class-container w-container">
              <div className="af-class-section-title">
                <h2 className="af-class-heading-15">Contact Us</h2>
              </div>
              <div className="w-layout-grid af-class-grid-contact">
                <div id="w-node-_8862d9d5-a6e6-5199-3acf-6f6c252abfa6-ed44a812">
                  <div className="af-class-contact-form w-form">
                    <form
                      id="wf-form-Contact-Form"
                      name="wf-form-Contact-Form"
                      data-name="Contact Form"
                      method="POST"
                      onSubmit={handleSubmit}
                    >
                      <div className="af-class-input-group">
                        <input
                          type="text"
                          className="af-class-form-input w-input"
                          maxLength={256}
                          name="name"
                          data-name="Name"
                          placeholder={useFormatMessage("contact.name") + '*'}
                          id="Name"
                          required
                        />
                      </div>
                      <div className="af-class-input-group">
                        <input
                          type="text"
                          className="af-class-form-input w-input"
                          maxLength={256}
                          name="company"
                          data-name="Company"
                          placeholder="Company"
                          id="Company"
                        />
                      </div>
                      <div className="af-class-input-group">
                        <input
                          type="text"
                          className="af-class-form-input w-input"
                          maxLength={256}
                          name="phone"
                          data-name="Phone"
                          placeholder={useFormatMessage("contact.phone")}
                          id="Phone"
                        />
                      </div>
                      <div className="af-class-input-group">
                        <input
                          type="email"
                          className="af-class-form-input w-input"
                          maxLength={256}
                          name="email"
                          data-name="Email"
                          placeholder={useFormatMessage("contact.email") + '*'}
                          id="Email"
                          required
                        />
                      </div>
                      <div className="af-class-input-group">
                        <input
                          type="text"
                          className="af-class-form-input w-input"
                          maxLength={256}
                          name="message"
                          data-name="Message"
                          placeholder={useFormatMessage("contact.message") + '*'}
                          id="Message"
                          required
                        />
                      </div>
                      <button type="submit" className="af-class-button w-button">
                        Submit
                      </button>
                    </form>
                    <div className="af-class-success-message w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="af-class-error-message w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="w-node-f89c808c-bdbe-9fa0-741a-fa6999cab4fb-ed44a812"
                  className="af-class-contact-info"
                >
                  <div className="af-class-contact-item">
                    <h6>{useFormatMessage("contact.email")}</h6>{" "}
                    <div className="af-class-text-block-4">
                      info@mimarliksolo.com
                    </div>
                    <a
                      href="mailto:info@mimarliksolo.com"
                      className="af-class-link-2-copy"
                    >
                      info@mimarliksolo.com
                    </a>
                  </div>
                  <div className="af-class-contact-item">
                    <h6>{useFormatMessage("contact.phone")}</h6>{" "}
                    <div className="af-class-text-block-5">
                      +90 (507) 444 64 62
                    </div>
                    <a
                      href="tel:+905074446462"
                      className="af-class-link-2-copy"
                    >
                      +90 (212) 871 7516
                    </a>
                  </div>
                  <div className="af-class-contact-item">
                    <h6>{useFormatMessage("contact.address")}</h6>{" "}
                    <div className="af-class-text-block-6">
                      Cihangir Mahsllesi Pürtelaş, <br />
                      Sokak No:3A Beyoğlu/ İstanbul
                    </div>
                    <a
                      href="https://goo.gl/maps/8RbYQLJX7fT2jNft9"
                      className="af-class-link-3-copy"
                    >
                      Cihangir Mahallesi Pürtelaş, <br />
                      Sokak No:3A Beyoğlu/ İstanbul
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </span>
  );
};

export default ContactView;

/* eslint-enable */
