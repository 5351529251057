// dynamicDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const dynamicDataSlice = createSlice({
  name: 'dynamicData',
  initialState: {
    data: null
  },
  reducers: {
    setDynamicData: (state, action) => {
      state.data = action.payload
    },
  },
});

export const { setDynamicData } = dynamicDataSlice.actions;
export default dynamicDataSlice.reducer;
