/* eslint-disable */

import GoogleFontsLoader from "../utils/GoogleFontsLoader";
import { useNavigate, useParams } from "react-router-dom";

import { useLanguageUtils } from "../utils/index";
import React, { useEffect, useState } from "react";
import { useFormatMessage } from "../hooks";
import { NavLinks } from "../components/NavLinks";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=63667a86befaedf883e34757"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("/js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

const DetailTeamView = () => {

  const Team = useSelector((state) => state.dynamicData.data?.Teams || []);  // State to hold team data

  const [teamMember, setTeamMember] = useState(null);

  // const [teamMember, setCurrentProduct] = useState(null);

  useEffect(() => {

    // Get the path from the current URL
    const currentPath = window.location.pathname;

    // Split the path by '/' and take the last segment as the page name
    const pathSegments = currentPath.split('/');
    const pageName = pathSegments[pathSegments.length - 1];

    console.log('Current Page Name:', pageName); // Debug: Output the current page name

    // Find the product that matches the current path
    const matchingMember = Team.find((Member) => {
      console.log('Checking Product:', Member); // Debug: Output each product for comparison
      console.log('Product URL:', Member.slug.data.url); // Debug: Output product URL
      return Member.slug.data === pageName;
    });

    console.log('Matching Product:', matchingMember); // Debug: Output the matching product


    // Set the matching product in state
    setTeamMember(matchingMember);


    // // Find the team data that matches the provided ID
    // const matchingMember = Team.find((team) => team.slug?.data === id);

    // if (matchingMember) {
    //   // Set the matching team data in state
    //   setTeamMember(matchingMember);
    // } else {
    //   // Handle the case when no matching team is found, e.g., redirect to a 404 page
    //   // history.push('/404');
    // }

    // This useEffect will re-run whenever 'id' or 'teams' change
  }, [Team]);

  // Find the team data that matches the provided ID
  // const teamMember = teams.find((team) => team.slug?.data === id);

  console.log('Member:', teamMember);

  
  // console.log(Team)

  // function getTeamMember() {
  //   // Get the team name from the URL
  //   const { id } = useParams();

  //   // Retrieve the team data from the JSON array
  //   const teamMember = Team.find(team => team.slug.data === id);
  //   console.log(teamMember)
    
  //     return teamMember;

    
  // }
  // const teamMember = getTeamMember();
  
  // window.onload = function() {
  //   getTeamMember();
  // };

    const { changeLocaleHandler, locale } = useLanguageUtils();
  //Language Part
  // const dispatch = useDispatch();
  // const changeLocaleHandler = (local) => {
  //     dispatch(setUserLocale(local));
  // };
  // const { locale } = useSelector(
  // (state) => ({
  //     locale: state.preferences.locale,
  // }),
  // shallowEqual
  // );
  // const locales = availableLocales.filter((local) => local !== locale);
  //---------------------

  const componentDidMount = () => {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6377c735e0b2ef548f44a816";
    htmlEl.dataset["wfSite"] = "63667a86befaedf883e34757";
  };
  componentDidMount();

  return (
    <span>
      {teamMember ? (
        <>
      <GoogleFontsLoader />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/solo-arch.webflow.css);
        `,
        }}
      />
      <span className="af-view">
        <div className="af-class-body-8">
                      <NavLinks
              useFormatMessage={useFormatMessage}
              locale={locale}
              changeLocaleHandler={changeLocaleHandler}
            />
          <div className="af-class-team-detail-section af-class-wf-section">
            <div className="af-class-container w-container">
              <div className="w-layout-grid af-class-grid-team-detail">
                <div
                  id="w-node-d185f31a-bf10-bc83-8422-8895b0061947-8f44a816"
                  className="af-class-team-detail-image-wrap"
                >
                  <img
                    alt="image"
                    src={teamMember.thumbnail.data.url}
                    loading="lazy"
                    className="af-class-team-detail-image"
                  />
                </div>
                <div
                  id="w-node-d185f31a-bf10-bc83-8422-8895b0061949-8f44a816"
                  className="af-class-team-detail"
                >
                  <div className="af-class-team-info">
                    <h1 className="af-class-heading-10">{teamMember.name.data}</h1>
                    <p className="af-class-lead-text">
                      {locale == "tr"
                        ? teamMember.shortdescription_tr.data
                        : teamMember.shortdescription.data}
                    </p>
                    <h4 className="af-class-team-phone-number">
                      {locale == "tr"
                        ? teamMember.jobtitle_tr.data
                        : teamMember.jobtitle.data}
                    </h4>
                    <div className="af-class-team-email" />
                    <div className="w-layout-grid af-class-team-detail-social">
                      <a
                        href="#"
                        className="af-class-team-detail-social-link w-inline-block"
                      >
                        <img
                          alt="image"
                          src="../images/facebook.svg"
                          loading="lazy"
                          className="af-class-team-detail-social-icon"
                        />
                      </a>
                      <a
                        href="#"
                        className="af-class-team-detail-social-link w-inline-block"
                      >
                        <img
                          alt="image"
                          src="../images/instagram-brands.svg"
                          loading="lazy"
                          className="af-class-team-detail-social-icon"
                        />
                      </a>
                      <a
                        href="#"
                        className="af-class-team-detail-social-link w-inline-block"
                      >
                        <img
                          alt="image"
                          src="../images/linkedin.svg"
                          loading="lazy"
                          className="af-class-team-detail-social-icon"
                        />
                      </a>
                      <a
                        href="#"
                        className="af-class-team-detail-social-link w-inline-block"
                      >
                        <img
                          alt="image"
                          src="../images/twitter.svg"
                          loading="lazy"
                          className="af-class-team-detail-social-icon"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </span>
      </>
      ) : (
      <Loader/>
      )}
    </span>
  );
};

export default DetailTeamView;

/* eslint-enable */
